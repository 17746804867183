<template>
    <div class="monitor">
        <div class="table-wrapper">
            <el-table
                border
                ref="ref_supplyTable"
                :data="dataList"
                class="tableStyle"
                height="100%"
                highlight-current-row
                @row-click="handleCurrentChange"
            >
                <el-table-column
                    prop="type_name"
                    label="原材料类型"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_theory"
                    label="当前库存(吨)"
                >
                </el-table-column>
                <el-table-column
                    prop="volume_max"
                    label="最大库存(吨)"
                >
                </el-table-column>
            </el-table>
        </div>
        <div class="monitor-wrapper">
            <div class="monitor-item" v-for="item in stockList" :key="item.station_id">
                <label>{{ item.station_name }}</label>
                <div class="monitor-block">
                    <div v-for="i in item.maxBlocks" :class="['block-item',i<=item.curBlocks?'active':'']" :key="item.station_id+i">
                        <span class="block-num" v-if="i==item.curBlocks||i==item.maxBlocks&&i<item.curBlocks">{{ item.volume_theory }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { eventBus } from '@/common/eventBus.ts';
import largeScreenFun from './JS/index.js';
import NP from 'number-precision';
NP.enableBoundaryChecking(false);

export default {
    name: 'monitor',
    components: {},
    props: {},
    data() {
        return {
            tenantId: '',
            stationId: '',
            activeRow: 0,
            // 数据列表
            dataList: [],
            // 料仓数据
            stockList: [],
        };
    },
    watch: {},
    computed: {},
    created() {},
    mounted() {
        eventBus.$on('updateMonitor', (tenantId, stationId) => {
            this.activeRow = 0;
            this.tenantId = tenantId;
            this.stationId = stationId;
            this.loadPage();
        });
    },
    methods: {
        async initPage() {
            this.tenantId = this.$parent.tenantId;
            this.activeRow = 0;
            await this.loadPage();
        },
        async loadPage() {
            await this.getTypeStock();
            if (this.dataList?.length > 0) {
                this.$nextTick(() => {
                    this.handleCurrentChange(this.dataList[this.activeRow]);
                    this.activeRow += 1;
                    if (this.activeRow === this.dataList.length) {
                        this.activeRow = 0;
                    }
                });
            } else {
                this.stockList = [];
            }
        },
        // 当前选中行变化
        handleCurrentChange(row) {
            this.activeRow = row.index;
            this.$refs.ref_supplyTable.setCurrentRow(row);
            // 最大库存 总共块数12个
            // 计算每个场站的最大库存和当前库存占用块数
            const splitVal = row.volume_max / 12 || 1;
            row.Stocks.forEach(item => {
                // 当前库存占用块数
                item.curBlocks = Math.round(item.volume_theory / splitVal);
                if (item.curBlocks < 1) {
                    item.curBlocks = 1;
                }
                // 最大库存占用块数
                item.maxBlocks = Math.round(item.volume_max / splitVal);
                if (item.maxBlocks < 1) {
                    item.maxBlocks = 1;
                }
            });
            this.stockList = row.Stocks;

            // 设置当前点击行始终出现在可视区域
            const vmEl = this.$refs.ref_supplyTable.$el;
            const targetTop = vmEl.querySelectorAll('.el-table__body tr')[this.activeRow].getBoundingClientRect().top;
            const conTop = vmEl.querySelector('.el-table__body').getBoundingClientRect().top;
            const height = vmEl.querySelector('.el-table__body-wrapper').getBoundingClientRect().height - 38;
            if (targetTop - conTop - height > this.$refs.ref_supplyTable.bodyWrapper.scrollTop) {
                this.$refs.ref_supplyTable.bodyWrapper.scrollTop = targetTop - conTop;
            }
        },

        // 获取料仓数据
        async getTypeStock() {
            const stockData = await largeScreenFun.getTypeStock(this.tenantId, this.stationId);
            stockData.Stocks.forEach((item, index) => {
                item.index = index;
            });
            this.dataList = stockData.Stocks;

        },
    },
};
</script>

<style scoped lang="stylus">
.monitor
    width 100%;
    height 100%;
    display flex
    padding 0.55rem 0.2rem 0.2rem;
    .table-wrapper
        width 4.5rem;
        height 100%;
        >>> .el-table
            th
                background #1B3153;
                box-shadow: 0rem 0rem 0.06rem 0rem #4A74AB inset;
            th.el-table__cell.is-leaf
                border-bottom 0
            td
                color #32c5ff
                &:first-child
                    color rgba(255, 255, 255, 0.8)
    .monitor-wrapper
        width calc(100% - 4.5rem);
        height 100%;
        padding-top 0.15rem
        overflow-y auto
        .monitor-item
            display flex
            margin-bottom 0.2rem
            label
                margin-right 0.15rem;
                display flex
                justify-content flex-end
                width 0.85rem
                font-size 0.14rem
                color #fff;
                opacity 0.8
            .monitor-block
                display flex
                .block-item
                    margin-right 0.1rem
                    width: 0.4rem;
                    height: 0.31rem;
                    background url("images/stock.png") center no-repeat
                    background-size 100% 100%;
                    position relative
                    &.active
                        background url("images/stock_hight.png") center no-repeat
                        background-size 100% 100%;
                    .block-num
                        display block
                        width 100%
                        position absolute
                        top -0.18rem
                        font-size: 0.16rem;
                        font-family: Technology-Bold, Technology;
                        color: #FA6400;
                        white-space nowrap
                        text-align center
</style>
